import React, { useState, useEffect } from "react";
import toast from 'react-hot-toast';
import ItemImg from "../../../../assets/recipes/bowl.avif";
import { getOrderDetailsByOrderNum } from "../../../../api/ordersAPI";
import { fetchPikizyTokenAndCheckIn } from "../../../../api/pikizyAPI";
import "./PopupFridge.css";

function PopupFridge({ onConfirm, isOpen, onActionSuccess, readyOrderNum }) {
  const [orderDetails, setOrderDetails] = useState(null);
  const [isFridgeOpen, setIsFridgeOpen] = useState(false);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!readyOrderNum) return;
      try {
        const details = await getOrderDetailsByOrderNum(readyOrderNum);
        setOrderDetails(details);
      } catch (error) {
        toast.error("Erreur lors de la récupération des détails de la commande");
        console.error("Erreur lors de la récupération des détails de la commande", error);
      }
    };

    fetchOrderDetails();
  }, [readyOrderNum]);

  if (!isOpen) return null;

  const handleYesClick = async () => {
    try {
      await fetchPikizyTokenAndCheckIn(orderDetails?.id);
      setIsFridgeOpen(true);
      onActionSuccess?.();
      toast.success('Le frigo a bien été ouvert');
    } catch (error) {
      toast.error("Erreur lors de l'ouverture du frigo");
      console.error("Erreur lors de l'ouverture du frigo", error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="popup-fridge-content flex-center">
          <h3 className="flex-center">Recapitulatif de votre commande</h3>
          <div className="popup-fridge-items flex-center">
            {orderDetails?.order_items?.map((item, index) => {
              const imageUrl = item.recipe.pictures?.length > 0
                ? `${process.env.REACT_APP_API_URL}${item.recipe.pictures[0].path}`
                : ItemImg;
              return (
                <div key={index} className="recipe-item-container">
                  <div className="recipe-img-container">
                    <img src={imageUrl} alt={item.recipe.name} className="recipe-img" />
                  </div>
                  <div className="popup-fridge-items-text">
                    <div><span className="item-label">Nom: </span>{item.recipe.name}</div>
                    <div><span className="item-label">Quantité: </span>{item.quantity}</div>
                    <div><span className="item-label">Prix: </span>{item.recipe.price}€</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="popup-fridge-instructions">
            <p>
              S'il vous plait, veuillez vous positionner devant le réfrigérateur, situé au 
              <br />
              <strong>
                <a href="https://maps.app.goo.gl/JWu6VhhBX7EkyjbB6" target="_blank" rel="noopener noreferrer">
                  RDC de la Climate House.
                </a>
              </strong>
            </p>
          </div>
          {isFridgeOpen ? (
            <div className="popup-fridge-actions flex-center">
              <button className="btn-full btn-sm" onClick={onConfirm}>
                Fermer
              </button>
            </div>
          ) : (
            <div>
              <div className="popup-fridge-confirmation">
                <p className="popup-fridge-actions-text flex-center">Voulez-vous ouvrir le frigo ?</p>
              </div>
              <div className="popup-fridge-actions flex-center">
                <button className="btn-full btn-sm" onClick={handleYesClick}>Oui</button>
                <button className="btn-border btn-sm" onClick={onConfirm}>Non</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PopupFridge;
