import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from '@mui/material'; // Import pour le chargement visuel
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { addToCart, updateCart } from '../../features/cart/cartSlice';
import { setPreorderEnabled } from '../../features/preorder/preorderSlice';
import { fetchNutriFacts } from '../../api/nutriFactsAPI';
import { fetchProductByRecipeId, fetchProductById } from '../../api/stockAPI';
import { fetchAllRecipePictures, fetchRecipeById, getAllRecipes } from '../../api/recipesAPI';
import { updateRecipePortionByMenuId } from '../../api/menuRecipesAPI';

import { Button } from '@mui/material';
import Chip from '@mui/joy/Chip';
import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Input from '@mui/joy/Input';
import PopupRecipeInfos from '../elements/popup/popup-recipe/PopupRecipeInfos';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';


import './Recipes.css';

// Nutri-Score Images
import NutriA from '../../assets/nutri-score/NutriscoreA.png';
import NutriB from '../../assets/nutri-score/NutriscoreB.png';
import NutriC from '../../assets/nutri-score/NutriscoreC.png';
import NutriD from '../../assets/nutri-score/NutriscoreD.png';
import NutriE from '../../assets/nutri-score/NutriscoreE.png';

function RecipeCard({
recipe,
isListContext = false,
isMenuContext = false,
isBuyingRecipe = false, // Ajout de cette prop
menuId,
onDelete,
onPortionChange,
updateTotalPrice,
isSelected = false, // Ajoutez cette prop
isExisting = false, // Ajoutez cette prop
onSelect, // Fonction callback pour la sélection
}) {
console.log("Données reçues dans RecipeCard :", recipe);

const [nutriFacts, setNutriFacts] = useState(null);
const [ingredients, setIngredients] = useState([]);
const [openPopup, setOpenPopup] = useState(false);
const [recipeDetails, setRecipeDetails] = useState(recipe || {});
const [recipePortions, setRecipePortions] = useState(recipe?.portions || 1);
const [totalRecipePrice, setTotalRecipePrice] = useState(recipe?.price || 0);
const [imageUrl, setImageUrl] = useState('/path/to/default-image.jpg');
const [productData, setProductData] = useState(null);
const [inputValue, setInputValue] = useState(recipe?.portions || 1);
const [isLoading, setIsLoading] = useState(true);
const [isAdmin, setIsAdmin] = useState(false);

const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

const dispatch = useDispatch();
const preorderEnabled = useSelector((state) => state.preorder.preorderEnabled);

const deliveryZones = ['75002'];
const userPostalCode = sessionStorage?.getItem('userPostalCode');
const isInDeliveryZone = deliveryZones.includes(userPostalCode);
const categoryMap = {
  1: "entrée",
  2: "plat",
  3: "dessert",
  4: "boisson",
};

const calculateRecipePrice = (productData, recipeDetails) => {
    if (!productData || !productData.productInfos || !recipeDetails) {
        console.log('Données manquantes pour le calcul du prix', { productData, recipeDetails });
        return 0;
    }

    const definedPortions = recipeDetails?.portions || 4; // Nombre de portions défini dans la recette (par défaut : 4)
    const selectedPortions = recipeDetails?.selectedPortions || 1; // Nombre de portions sélectionnées par l'utilisateur

    const totalPrice = productData.productInfos.reduce((accumulator, productInfo) => {
        if (!productInfo.price || !productInfo.unitQty) {
            console.warn(`Produit manquant ou données invalides pour l'ID ${productInfo.id}`);
            return accumulator;
        }

        // Ajuste la quantité par portion définie
        const adjustedQty = (productInfo.unitQty / definedPortions) * selectedPortions;

        // Prix par gramme
        const pricePerGram = parseFloat(productInfo.price) / 1000;

        // Coût total pour ce produit
        const productPrice = pricePerGram * adjustedQty;

        console.log(`Produit ${productInfo.id}`, {
            definedPortions,
            selectedPortions,
            adjustedQty,
            pricePerGram,
            productPrice,
        });

        return accumulator + productPrice; // Ajoute le prix de ce produit
    }, 0);

    return totalPrice; // Retourne le prix total
};

useEffect(() => {
    const roles = localStorage.getItem('roles');
    if (roles && roles.includes('admin')) {
        setIsAdmin(true);
    }
}, []);

useEffect(() => {
    const fetchData = async () => {
      try {
        // Début du chargement
        setIsLoading(true);
  
        // Vérification de la présence d'un ID de recette
        if (!recipe?.id) {
          console.warn("Aucune recette fournie pour le chargement des données.");
          setIsLoading(false);
          return;
        }
  
        // Exécuter toutes les requêtes en parallèle
        const [
          picturesRes,
          fetchedRecipe,
          fetchedNutriFacts,
          rawData
        ] = await Promise.all([
          fetchAllRecipePictures(),      // 1) Toutes les images
          fetchRecipeById(recipe.id),    // 2) Les détails de la recette
          fetchNutriFacts(recipe.id),    // 3) Les infos nutritionnelles
          fetchProductByRecipeId(recipe.id) // 4) Les produits liés à la recette
        ]);
  
        // 1) Gérer les images
        const recipePicture = picturesRes.find(
          (picture) => picture.recipe_id === recipe.id
        );
        const imagePath = recipePicture
          ? `${process.env.REACT_APP_API_URL}${recipePicture.path}`
          : '/path/to/default-image.jpg';
        setImageUrl(imagePath);
  
        // 2) Gérer les détails de la recette
        setRecipeDetails(fetchedRecipe || {});
  
        // 3) Gérer les infos nutritionnelles
        setNutriFacts(fetchedNutriFacts || {});
  
        // 4) Gérer les produits liés
        if (!rawData || !rawData.productIds) {
          console.warn("Structure inattendue dans fetchProductByRecipeId. Vérifiez la réponse API.");
          return;
        }
  
        const detailedIngredients = await Promise.all(
          rawData.productIds.map(async (product) => {
            try {
              const details = await fetchProductById(product.productId);
              return details;
            } catch (error) {
              console.error(`Erreur pour le produit ${product.productId}`, error);
              return null;
            }
          })
        );
  
        // Filtrer les éventuels produits invalides
        setIngredients(detailedIngredients.filter(Boolean));
  
        // Construire l'objet productInfos pour calculer le prix
        const productInfos = detailedIngredients
          .filter(Boolean)
          .map((product) => ({
            id: product.id,
            price: product.price,
            unitQty: product.unitQty,
          }));
  
        setProductData({ productInfos });
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
        // Fin du chargement
        setIsLoading(false);
      }
    };
  
    // Lancer la récupération des données seulement si on a un recipe.id
    if (recipe?.id) {
      fetchData();
    }
  }, [recipe?.id]);

  
useEffect(() => {
    if (productData && recipeDetails) {
        const newPrice = calculateRecipePrice(productData, {
            ...recipeDetails,
            selectedPortions: recipePortions // Passe le nombre de portions sélectionné
        });
        setTotalRecipePrice(newPrice.toFixed(2));
        console.log(`Prix total de la recette pour ${recipePortions} portions : ${newPrice.toFixed(2)} €`);
    } else {
        console.warn('Les données nécessaires au calcul du prix ne sont pas prêtes.');
    }
}, [recipePortions, productData, recipeDetails]);

const handleInputChange = (e) => {
    const value = e.target.value;

    // Permettre une saisie vide temporairement
    if (value === '' || /^\d*$/.test(value)) {
        setInputValue(value); // Met à jour uniquement l'affichage temporaire
    }
};

const handleInputBlur = () => {
    const newPortions = Math.max(1, parseInt(inputValue, 10) || 1);
    setRecipePortions(newPortions);
    setInputValue(newPortions);

    console.log("Nouvelles portions définies :", newPortions);

    if (productData) {
        const newPrice = calculateRecipePrice(productData, {
            ...recipeDetails,
            selectedPortions: newPortions
        });
        const roundedPrice = parseFloat(newPrice.toFixed(2));
        setTotalRecipePrice(roundedPrice);

        console.log("Prix calculé localement :", roundedPrice);

        if (isMenuContext) {
            console.log("Envoi des données à l'API :", {
                menuId,
                recipeId: recipe.id,
                portions: newPortions,
                price: roundedPrice,
            });

            updateRecipePortionByMenuId(menuId, recipe.id, {
                portions: newPortions,
                price: roundedPrice, // Envoyer le prix arrondi
            }).then((response) => {
                console.log("Réponse de l'API :", response);
                // Afficher le toast de succès après la mise à jour réussie
                toast.success(`Portions mises à jour avec succès : ${newPortions} portions.`);
            }).catch((error) => {
                console.error("Erreur lors de l'appel à l'API :", error);
            });
        }
    }
};

const handleAddToCart = () => {
  let currentCart = JSON.parse(localStorage.getItem('cart')) || [];
  const currentTime = new Date().getTime();
  const cartExpiration = localStorage.getItem('cartExpiration');

  if (!currentCart.length || currentTime > cartExpiration) {
      currentCart = [];
      localStorage.removeItem('cartExpiration');
  }

  const itemIndex = currentCart.findIndex((item) => item.id === recipe.id);
  const productQuantityInCart = itemIndex !== -1 ? currentCart[itemIndex].quantity : 0;

  if (preorderEnabled || productQuantityInCart < recipe.qty_available) {
      if (itemIndex !== -1) {
          currentCart[itemIndex].quantity += 1;
      } else {
          currentCart.push({
              id: recipe.id,
              name: recipe.name,
              price: recipe.price,
              quantity: 1,
              image: recipe.pictures?.[0]?.path || '',
              category: categoryMap[recipe.partId] || 'autre', // Ajout de la catégorie
              partId: recipe.partId, // Ajout explicite de partId
              qty_available: recipe.qty_available, // Ajoutez qty_available ici
          });
      }

      localStorage.setItem('cart', JSON.stringify(currentCart));
      localStorage.setItem('cartExpiration', currentTime + 2 * 3600000);

      dispatch(addToCart({
          id: recipe.id,
          name: recipe.name,
          price: recipe.price,
          quantity: 1,
          image: recipe.pictures?.[0]?.path || '',
          category: categoryMap[recipe.partId] || 'autre', // Ajout de la catégorie
          partId: recipe.partId, // Ajout explicite de partId
          qty_available: recipe.qty_available, // Ajoutez qty_available ici
      }));

      dispatch(updateCart(currentCart));
      toast.success(`${recipe.name} a été ajouté au panier`);
  } else {
      toast.error(`La quantité maximale de ${recipe.name} est atteinte`);
  }
};

const handlePreorderClick = () => {
    dispatch(setPreorderEnabled(true));
    toast.success('Mode précommande activé !');
};

const buttonText =
recipe.qty_available === 0 && !preorderEnabled
    ? 'Revient bientôt -> Précommander'
    : preorderEnabled || isInDeliveryZone
    ? 'Ajouter au panier'
    : 'Indisponible';


const buttonAction =
recipe.qty_available === 0 && !preorderEnabled
? () => {
handlePreorderClick(); // Active la précommande
toast.success('Précommande activée ! Vous pouvez ajouter l\'article au panier.');
}
: preorderEnabled || isInDeliveryZone
? handleAddToCart // Action d'ajout au panier
: null;

const nutriScoreImage = {
    A: NutriA,
    B: NutriB,
    C: NutriC,
    D: NutriD,
    E: NutriE,
}[nutriFacts?.nutriscore] || null;

const handleDelete = async () => {
    if (onDelete) {
        await onDelete(recipe.id);
        updateTotalPrice?.();
    }
};
console.log('Props reçues dans PopupRecipeInfos :', { ingredients });

return (
  <Card
    className={`recipe-card ${isSelected ? 'selected' : ''} ${
      isExisting ? 'disabled' : ''
    }`}
    onClick={!isExisting && onSelect ? () => onSelect(recipe.id) : undefined}
    sx={{
      marginBottom: '0.5rem',
      maxWidth: isMobile ? '100%' : 400,
      minHeight: 100,
      minWidth: 200,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap', // Wrap uniquement sur mobile
      cursor: !isExisting && onSelect ? 'pointer' : 'default',
      opacity: isExisting ? 0.5 : 1, // Diminue l'opacité si la recette est existante
    }}
  >
    {/* Image de la recette */}
    <div className="image-column" style={{ flex: 1 }}>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        <img
          loading="lazy"
          src={imageUrl}
          alt={recipeDetails?.name || 'Recette'}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/path/to/default-image.jpg';
          }}
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
        />
      )}
    </div>

    {/* Contenu de la carte */}
    <div className="info-column" style={{ flex: 2, padding: '0.3rem' }}>
      {/* Titre et NutriScore */}
      <div
        className="recipeFirstLine"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isLoading ? (
          <Skeleton variant="text" width="80%" height={24} />
        ) : (
          <Typography level="h6" className="card-title">
            {recipeDetails?.name || 'Nom de la recette manquant'}
          </Typography>
        )}
      </div>

      {/* Description */}
      <Typography className="card-description" sx={{ marginTop: '0.5rem' }}>
        {isLoading ? (
          <Skeleton variant="text" width="90%" height={20} />
        ) : recipeDetails?.description?.length > 100 ? (
          `${recipeDetails.description.substring(0, 100)}...`
        ) : (
          recipeDetails.description || 'Description non disponible'
        )}
      </Typography>

      {/* Ligne flex pour Info +, Prix et NutriScore */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',   
          alignItems: 'center',
          gap: 2,
          marginTop: '0.5rem',
          overflow: 'visible',  
        }}
      >
        {/* Bouton Voir les ingrédients */}
        { isLoading ? (
            <Skeleton variant="text" width="50%" height={20} />
          ) : (
            <Typography
              className="card-content-ingredients"
              level="body"
              sx={{
                fontSize: 14,
                backgroundColor: '#1C4127', // Fond vert
                color: '#fff',              // Texte blanc
                cursor: 'pointer',
                padding: '0.15rem 0.5rem',  // Padding réduit pour la hauteur
                borderRadius: '4px',
                maxWidth: '60px',
                lineHeight: 1.2,
              }}
              onClick={() => setOpenPopup(true)}
            >
              Info +
            </Typography>
        )}
        {/* NutriScore */}
        {nutriScoreImage && !isLoading && (
          <img
            src={nutriScoreImage}
            alt={`NutriScore ${nutriFacts?.nutriscore}`}
            style={{ width: '60px' }}
          />
        )}
        {/* Prix de la recette */}
        {!isMenuContext && !isListContext && (
          <Typography
            className="recipe-price"
            fontWeight="bold"
            sx={{ marginBottom: '0.1rem', width: '45px' }}
          >
            {recipe.price ? `${recipe.price}€` : 'Prix non disponible'}
          </Typography>
        )}

        
      </Box>

      {/* Calories et autres informations */}
      {isMenuContext && (
        <div
          className="card-calories-score"
          style={{
            marginTop: '0.5rem',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          {isLoading ? (
            <>
              <Skeleton
                variant="rectangular"
                width="100px"
                height={36}
                sx={{ marginBottom: '0.3rem' }}
              />
              <Skeleton variant="rectangular" width="60%" height={24} />
            </>
          ) : (
            <>
              {/* Input pour les portions */}
              <div style={{ marginBottom: '0.5rem' }}>
                <Typography level="body-xs" sx={{ marginBottom: '0.5rem' }}>
                  Portions
                </Typography>
                <Input
                  type="number"
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  sx={{ width: '100px' }}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    min: 1,
                  }}
                />
              </div>

              {/* Calories */}
              {nutriFacts?.calories && nutriFacts?.portions ? (
                <Chip variant="soft" color="primary">
                  {Math.round(nutriFacts.calories / nutriFacts.portions)} kcal
                </Chip>
              ) : (
                <Typography fontSize="sm">Calories non disponibles</Typography>
              )}
            </>
          )}

          {/* Prix de la recette (Admin uniquement) */}
          {isMenuContext && isAdmin && (
            isLoading ? (
              <Skeleton
                variant="text"
                width="40%"
                height={24}
                sx={{ marginBottom: '1rem' }}
              />
            ) : (
              <Typography
                className="card-price"
                fontWeight="bold"
                sx={{ marginBottom: '1rem' }}
              >
                {totalRecipePrice} €
              </Typography>
            )
          )}
        </div>
      )}

      

      {/* Bouton d'action ou de suppression */}
      <div style={{ marginTop: '1rem' }}>
        {isLoading ? (
          <Skeleton variant="rectangular" width="100px" height={36} />
        ) : (
          <>
            {/* Bouton de suppression visible uniquement dans le contexte menu */}
            {isMenuContext ? (
              <IconButton
                onClick={handleDelete}
                color="error"
                sx={{ marginTop: '0.2rem' }}
              >
                <HighlightOffIcon />
              </IconButton>
            ) : !isListContext ? (
              /* Bouton d'action visible uniquement si le contexte n'est pas menu ou liste */
              <Button
                    className="card-action"
                    onClick={buttonAction}
                    variant="contained"
                    disabled={!buttonAction}
                    sx={{
                        backgroundColor: '#1C4127',       // Couleur de fond personnalisée
                        color: '#fff',                    // Couleur du texte (blanc si souhaité)
                        '&:hover': {
                        backgroundColor: '#16351E',     // Couleur de fond lors du survol (optionnelle, ajustez selon vos préférences)
                        },
                        // Vous pouvez ajouter d'autres styles ici si nécessaire
                    }}
                    >
                    {buttonText}
                    </Button>
            ) : null}
          </>
        )}
      </div>
    </div>

    {/* Popup pour les ingrédients */}
    {!isLoading && (
      <PopupRecipeInfos
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        ingredients={ingredients}
        title={`Ingrédients - ${recipeDetails.name}`}
        description={recipeDetails.description || 'Pas de description disponible'}
        imageUrl={imageUrl || 'Pas dimage disponible'}
      />
    )}
  </Card>
);



}

export default RecipeCard;