function OrderCondition({ isChecked, setIsChecked }) {

    return (
        <div className='order-condition-container'>
            <div className="order-condition-input">
                <input
                    id="checkbox"
                    type="checkbox"
                    className="order-input-checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                />
            </div>
            <div className="order-condition-text">
                <span style={{ fontWeight: 600 }}>Le plat est livré froid. </span>
                Tous nos plats se réchauffent au moins au micro-ondes à environ 700w pendant 2min30, si nécessaire.
                Vous pouvez retrouver nos conseils de réchauffage sur
                <a className='link-underline' href="https://mamossa.com/chochocho" target="_blank" rel="noopener noreferrer nofollow"> mamossa.com/chochocho</a> 🔥
            </div>

        </div>


    );
}

export default OrderCondition;