// src/components/orders/OrderValidation.jsx
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

// API imports
import { createOrder, updateOrderById } from '../../api/ordersAPI';
import { createInstantPayment } from '../../api/paygreenAPI';
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from '../../api/userAPI';
import { sendSummaryMail, sendGroupOrderMail } from "../../api/mailingAPI";
import { createGroupOrder } from '../../api/groupOrdersAPI';
import { createGroupParticipant } from '../../api/groupOrdersParticipantsAPI';

// Component imports
import Button from '../../components/elements/button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';

import CartItem from '../../components/side-cart/CartItem';
import OrderPrice from '../../components/orders/OrderPrice';
import OrderCondition from '../../components/orders/OrderCondition';
import PreorderDate from '../../components/orders/preorders/PreorderDate';
import Addresses from '../../components/orders/Addresses';
import TypeOfDelivery from '../../components/orders/TypeOfDelivery';
import GroupOrderConditions from '../../components/orders/GroupOrderConditions';
import { calculateTotalWithMenus, detectMenus } from '../../features/cart/cartSlice';

import '../../components/orders/Order.css';
import 'reactjs-popup/dist/index.css';

function OrderValidation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Sélection des états globaux depuis Redux
    const { selectedDate, selectedTime, selectedAddress, preorderEnabled } = useSelector(state => state.preorder);
    const cart = useSelector(state => state.cart.items);
    const totalTTC = useSelector(state => state.cart.totalTTC);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const groupOrderId = useSelector(state => state.cart.groupOrderId); // Utilisé si nécessaire
    const tvaRate = 0.055; // Taux de TVA (5.5%)
     // Utilisation du useMemo
     const { total, menus, totalBeforeDiscount, discountAmount } = useMemo(
        () => calculateTotalWithMenus(cart), // Fonction de calcul
        [cart] // Dépendance : recalcul seulement si le panier change
      );
      
    const { totalHT, tvaAmount } = useMemo(() => {
        const totalHT = (total / (1 + tvaRate)).toFixed(2); // Calcul HT
        const tvaAmount = (total - totalHT).toFixed(2); // Calcul TVA
        return { totalHT, tvaAmount };
    }, [total]); // Recalcule seulement si "total" change

    // Stockage local et conditions de livraison
    const storedDate = localStorage.getItem('selectedDate');
    const storedTime = localStorage.getItem('selectedTime');
    const finalSelectedDate = selectedDate || storedDate;
    const finalSelectedTime = selectedTime || storedTime;
    const isInDeliveryZone = JSON.parse(sessionStorage.getItem('isInDeliveryZone') || "false");
    const postalCode = useSelector(state => state.deliveryZone.userPostalCode);


    // États locaux
    const [userInfos, setUserInfos] = useState({});
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [groupOrderConditionsChecked, setGroupOrderConditionsChecked] = useState(false);

    const totalInCents = Math.round(totalTTC * 100);
    

    // Récupération des informations de l'utilisateur au montage
    useEffect(() => {
        async function init() {
            try {
                const userInfo = await fetchCurrentUserInfos();
                setUserInfos(userInfo);
                console.log("User info loaded:", userInfo);

                const orders = await fetchCurrentUserOrders(userInfo.id);
                console.log("Fetched orders for user:", orders);

                // Ne pas réinitialiser une commande payée (payment_status: true)
                const currentOrder = orders.find(
                    order => order.status === 'accepted' && order.payment_status === false
                );
                if (currentOrder) {
                    console.log("Commande acceptée non payée détectée, aucune action requise.");
                }
            } catch (error) {
                console.error("Failed to fetch user info or reset order:", error);
                toast.error("Une erreur est survenue lors du chargement des informations.");
            }
        }
        init();
    }, []);

    // Stockage des informations de commande dans localStorage
    useEffect(() => {
        localStorage.setItem('total_amount', totalTTC.toString());
        localStorage.setItem('selectedDate', finalSelectedDate || '');
        localStorage.setItem('selectedTime', finalSelectedTime || '');
    }, [totalTTC, finalSelectedDate, finalSelectedTime]);

     // Autres hooks comme useEffect pour gérer les effets secondaires
     useEffect(() => {
        // Exemple d'effet secondaire
        console.log('Récapitulatif mis à jour');
    }, [total, discountAmount]);

    // Validation des informations utilisateur et adresse
    const validateUserAndAddress = (user, isChecked, selectedAddress, groupOrderConditionsChecked, isInDeliveryZone) => {
        if (!user.id) {
            toast.error('Veuillez vous connecter pour continuer.');
            return false;
        }
        if (!isChecked) {
            toast.error('Veuillez accepter les conditions pour continuer.');
            return false;
        }
        if (!isInDeliveryZone && !selectedAddress) {
            toast.error('Veuillez sélectionner une adresse de livraison.');
            return false;
        }
        if (!isInDeliveryZone && !groupOrderConditionsChecked) {
            toast.error('Veuillez accepter les conditions de commande groupée.');
            return false;
        }
        return true;
    };
    
    // Utilisation dans `handleOrder`
    const handleOrder = async () => {
        if (!validateUserAndAddress(userInfos, isChecked, selectedAddress, isInDeliveryZone)) {
            return;
        }
    
        setLoading(true);
        try {
            // Création de la commande
            const orderData = {
                userId: userInfos.id,
                price: Math.round(total * 100),
                items: cart,
            };
    
            const createdOrder = await createOrder(orderData);
            console.log("Commande créée :", createdOrder);
    
            // Redirection vers la passerelle de paiement
            const payment = await createInstantPayment(orderData.price, createdOrder.id);
            if (payment?.instantPayment?.data?.hosted_payment_url) {
                window.location.href = payment.instantPayment.data.hosted_payment_url;
            } else {
                throw new Error("Erreur lors de la création du paiement.");
            }
        } catch (error) {
            console.error("Erreur lors de la validation de la commande :", error);
            toast.error("Une erreur est survenue. Veuillez réessayer.");
        } finally {
            setLoading(false);
        }
    };
    
    

    // Fonction pour préparer la commande
    const buildOrderData = (userId, stripeAmount, cartItems, preorderState, selectedAddress) => {
        const cartitems = cartItems.map(item => ({
            recipeId: item.id,
            quantity: item.quantity,
        }));
    
        let deliveryType = "pickup"; // Valeur par défaut
    
        // Déterminer le type de livraison
        if (!isInDeliveryZone) {
            deliveryType = "grouporder"; // Commande groupée
        } else if (preorderState.preorderEnabled) {
            deliveryType = "preorder"; // Précommande
        }
    
        console.log("Determined delivery type:", deliveryType);
    
        const addressId = parseInt(selectedAddress, 10);
        const formattedSelectedDate = preorderState.selectedDate
            ? dayjs(preorderState.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null;
    
        let deliveryTime = null;
    
        if (formattedSelectedDate) {
            const [hours, minutes, seconds] = (preorderState.selectedTime || "00:00:00").split(':').map(Number);
            const deliveryDateTime = dayjs(formattedSelectedDate)
                .set('hour', hours)
                .set('minute', minutes)
                .set('second', seconds);
    
            if (deliveryDateTime.isValid()) {
                deliveryTime = deliveryDateTime.format('YYYY-MM-DD HH:mm:ss');
            } else {
                console.error('Invalid deliveryDateTime:', deliveryDateTime);
                toast.error("Date ou heure de livraison invalide.");
                return null;
            }
        }
    
        // Construire l'objet orderData
        const orderData = {
            userId,
            price: stripeAmount,
            deliveryType,
            cartitems,
        };
    
        // Ajouter addressId et deliveryTime si pertinents
        if (deliveryType === "preorder" || deliveryType === "grouporder") {
            orderData.addressId = addressId;
            orderData.deliveryTime = deliveryTime;
        }
    
        console.log("Built order data:", orderData);
    
        return orderData;
    };
    
    
    // Fonction mise à jour
    const updateLastNewOrder = async (userId, stripeAmount, additionalOrderData = {}) => {
        try {
            const orders = await fetchCurrentUserOrders(userId);
            console.log("Fetched orders:", orders);

            let currentOrder = orders.find(
                order => order.status === 'new' || (order.status === 'accepted' && order.payment_status === false)
            );

            // Préparer les données de la commande de base
            const baseOrderData = {
                userId,
                status: 'accepted',
                payment_status: false,
                price: stripeAmount,
                order_items: cart.map(item => ({
                    id: item.id,
                    quantity: item.quantity,
                })),
            };

            // Fusionner avec les données supplémentaires de buildOrderData
            const mergedOrderData = {
                ...baseOrderData,
                ...additionalOrderData, // Les champs de buildOrderData prennent la priorité
            };

            console.log("Base order data:", baseOrderData);
            console.log("Additional order data:", additionalOrderData);
            console.log("Merged order data:", mergedOrderData);

            if (!currentOrder) {
                console.log("Aucune commande active trouvée. Création d'une nouvelle commande.");
                currentOrder = await createOrder(mergedOrderData);
                console.log("New order created:", currentOrder);
            } else {
                const updatedOrder = {
                    ...currentOrder,
                    ...mergedOrderData,
                };

                console.log("Updated order data before API call:", updatedOrder);
                currentOrder = await updateOrderById(currentOrder.id, updatedOrder);
                console.log("Order after update:", currentOrder);
            }

            // Récupérer `orderId` et `order_num`
            const orderId = currentOrder.data?.id;
            const orderNum = currentOrder.data?.order_num;

            console.log("Extracted Order ID:", orderId);
            console.log("Extracted Order Num:", orderNum);
            console.log("Extracted Order Type:", currentOrder.data?.type); // Ajout du log pour 'type'

            if (!orderId || !orderNum) {
                throw new Error("Impossible de récupérer l'ID ou le numéro de la commande.");
            }

            return { orderId, orderNum }; // Retourne les deux

        } catch (error) {
            console.error("Erreur dans updateLastNewOrder :", error);
            throw error;
        }
    };

  

// Fonction de paiement mise à jour
const handlePaygreenPayment = async () => {
    try {
        setLoading(true);
        console.log("Validation des informations utilisateur et adresse...");
        
        const isValid = validateUserAndAddress(
            userInfos,
            isChecked,
            selectedAddress,
            groupOrderConditionsChecked,
            isInDeliveryZone
        );

        if (!isValid) {
            setLoading(false);
            return;
        }

        // Calcul du prix total basé sur les menus
        const { total } = calculateTotalWithMenus(cart);
        const stripeAmount = Math.round(total * 100); // Convertir en centimes pour Stripe

        console.log("Prix total envoyé à Stripe :", stripeAmount);

        const orderData = buildOrderData(
            userInfos.id,
            stripeAmount,
            cart,
            { preorderEnabled, selectedDate, selectedTime },
            selectedAddress
        );

        const { orderId, orderNum } = await updateLastNewOrder(userInfos.id, stripeAmount, orderData);

        const paymentResult = await createInstantPayment(
            stripeAmount,
            `${orderNum}`,
            orderId
        );

        if (paymentResult?.instantPayment?.data?.hosted_payment_url) {
            window.location.href = paymentResult.instantPayment.data.hosted_payment_url;
        } else {
            throw new Error("Échec de la création du paiement.");
        }
    } catch (error) {
        console.error("Erreur lors du traitement du paiement :", error);
        toast.error("Le paiement a échoué. Veuillez réessayer.");
    } finally {
        setLoading(false);
    }
};


// Fonction mise à jour : gestion des commandes groupées
const handleGroupOrder = async (createdOrder, userInfos, cartItems, postalCode, selectedAddress) => {
    try {
        if (!isInDeliveryZone && groupOrderConditionsChecked) {
            const orderId = createdOrder.id;
            const groupOrderData = { postal_code: postalCode };

            console.log("Création de la commande groupée pour la commande ID :", orderId);

            const newGroupOrder = await createGroupOrder(groupOrderData);
            if (!newGroupOrder) {
                throw new Error("Échec de la création de la commande groupée.");
            }

            const groupParticipantData = {
                address_id: parseInt(selectedAddress, 10),
                order_id: orderId,
                user_id: userInfos.id,
                group_order_id: newGroupOrder.id
            };

            const newParticipant = await createGroupParticipant(groupParticipantData);
            console.log("Participant ajouté :", newParticipant);

            const mailData = {
                name: userInfos.firstname,
                email: userInfos.email,
                order: { order_num: createdOrder.order_num },
                cartItems,
                groupOrder: {
                    postal_code: newGroupOrder.postal_code,
                    max_participants: newGroupOrder.max_participants,
                    current_participants: newGroupOrder.current_participants
                }
            };

            await sendGroupOrderMail(mailData);
            console.log("Email pour la commande groupée envoyé.");
        }
        
    } catch (error) {
        console.error("Erreur dans handleGroupOrder :", error.message);
        throw error;
    }
};




return (
    <div className="order-validation-container">
        {loading && (
            <div className="loader-overlay">
                <CircularProgress className="loader" color="success" />
            </div>
        )}
        <div className="order-validation-title flex-center">
            <h1>Voici votre récapitulatif, {userInfos.firstname}</h1>
        </div>
        <div className="order-validation-action flex-center">
            <Button
                className="btn-border btn-xl"
                text="Changer la commande"
                onClick={() => navigate('/home')}
            />
        </div>

        {/* Liste des articles */}
        <div className="order-validation-items">
            {cart.length > 0 ? (
                cart.map((item) => (
                    <CartItem
                        key={item.id}
                        item={item}
                        isInDeliveryZone={isInDeliveryZone}
                        preorderEnabled={preorderEnabled}
                    />
                ))
            ) : (
                <p>Votre panier est vide</p>
            )}
        </div>

        {/* Résumé des prix */}
        <div className="order-validation-price flex-center">
            <h2>Résumé de la commande</h2>
            <p>Total TTC avant réduction : {totalBeforeDiscount} €</p>

            <div className="order-summary">
                <h2>Récapitulatif de la commande</h2>
                {menus.simpleMenuCount > 0 && (
                    <p>Menu Duo (Entrée + Plat) : {menus.simpleMenuCount} x 9,90 €</p>
                )}
                {menus.platDessertMenuCount > 0 && (
                    <p>Menu Duo (Plat + Dessert) : {menus.platDessertMenuCount} x 9,90 €</p>
                )}
                {menus.fullMenuCount > 0 && (
                    <p>Menu Trio (Entrée + Plat + Dessert) : {menus.fullMenuCount} x 12,90 €</p>
                )}
        
                <p>Réduction Formules : -{discountAmount} €</p>
                <p>Total HT : {totalHT} €</p>
                <p>TVA (5,5%) : {tvaAmount} €</p>
                <p>Total TTC après réduction : {total} €</p>
            </div>

        </div>

        {/* Type de livraison */}
        <div className="flex-center">
            <TypeOfDelivery preorderEnabled={preorderEnabled} />
        </div>

        {/* Adresse et conditions si nécessaire */}
        {(preorderEnabled || !isInDeliveryZone) && (
            <div className="order-validation-address-section">
                {preorderEnabled && (
                    <div className="order-validation-preorder">
                        <div className="order-validation-date flex-center">
                            <PreorderDate />
                        </div>
                    </div>
                )}
                <div className="order-validation-addresses flex-center">
                    <Addresses postalCode={postalCode} setLoading={setLoading} />
                </div>
                {!isInDeliveryZone && (
                    <div className="flex-center">
                        <GroupOrderConditions
                            groupOrderConditionsChecked={groupOrderConditionsChecked}
                            setGroupOrderConditionsChecked={setGroupOrderConditionsChecked}
                        />
                    </div>
                )}
            </div>
        )}

        {/* Conditions générales */}
        <div className="order-validation-condition flex-center">
            <OrderCondition isChecked={isChecked} setIsChecked={setIsChecked} />
        </div>

        {/* Bouton de validation */}
        <div className="order-validation-checkout flex-center">
            <Button
                className="btn-border btn-xl"
                text="Régler la commande"
                onClick={handlePaygreenPayment}
            />
        </div>
    </div>
);

}

export default OrderValidation;
